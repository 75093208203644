<template>
  <v-container>
    <v-row v-if="mockupPart2">
      <v-col cols="6">
        <v-card class="pa-3">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="bgSrc"
                label="Link To Background Img"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="creativeSrc"
                label="Link To Creative Img"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-slider
                label="Grayscale"
                max="100"
                min="0"
                v-model="grayscale"
                @change="changeStyling('grayscale', grayscale)"
              ></v-slider>
              <v-slider
                label="Blur"
                max="100"
                min="0"
                v-model="blur"
              ></v-slider>
              <v-slider
                label="Contrast"
                max="500"
                min="100"
                v-model="contrast"
              ></v-slider>
              <v-slider
                label="Hue Rotate"
                max="50"
                min="-50"
                v-model="hue"
              ></v-slider>
              <v-slider
                label="Opacity"
                max="1"
                min="0"
                v-model="opacity"
              ></v-slider>
              <v-slider
                label="Invert"
                max="100"
                min="0"
                v-model="invert"
              ></v-slider>
              <v-slider
                label="Saturate"
                max="500"
                min="100"
                v-model="saturate"
              ></v-slider>
              <v-slider
                label="Sepia"
                max="100"
                min="0"
                v-model="sepia"
              ></v-slider>
            </v-col>
            <v-col cols="3"
              ><v-text-field
                type="number"
                label="Height (Creative):"
                v-model="creativeHeight"
                class="ml-2"
              >
              </v-text-field
            ></v-col>

            <v-col cols="3"
              ><v-text-field
                type="number"
                label="Width (Creative):"
                v-model="creativeWidth"
                class="mr-2"
              >
              </v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"
              ><v-text-field
                type="number"
                label="x:"
                v-model="leftPosition"
                class="ml-2"
              >
              </v-text-field
            ></v-col>

            <v-col cols="2"
              ><v-text-field
                type="number"
                label="y:"
                v-model="upPosition"
                class="mr-2"
              >
              </v-text-field
            ></v-col>
            <v-col cols="2"
              ><v-text-field
                type="number"
                label="warp (x):"
                v-model="warpx"
                class="ml-2"
              >
              </v-text-field
            ></v-col>

            <v-col cols="2"
              ><v-text-field
                type="number"
                label="warp (y):"
                v-model="warpy"
                class="mr-2"
              >
              </v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"
              ><v-btn color="primary" @click="upPosition--"
                ><v-icon>mdi-arrow-up</v-icon></v-btn
              ></v-col
            >
            <v-col cols="2"
              ><v-btn color="primary" @click="upPosition++"
                ><v-icon>mdi-arrow-down</v-icon></v-btn
              ></v-col
            >
            <v-col cols="2"
              ><v-btn color="primary" @click="leftPosition--"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              ></v-col
            >
            <v-col cols="2"
              ><v-btn color="primary" @click="leftPosition++"
                ><v-icon>mdi-arrow-right</v-icon></v-btn
              ></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="2"
              ><v-btn color="primary" @click="saveScreenshot"
                ><v-icon>mdi-content-save</v-icon></v-btn
              ></v-col
            >
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6" class="mt-4">
        <!-- <canvas id="insituMockup" style="width: 100%; height: 100%"></canvas> -->
        <div id="insituMockup">
          <v-img :src="bgSrc" ref="insituPreview">
            <div
              :style="{
                position: 'absolute',
                top: `${upPosition}px`,
                left: `${leftPosition}px`,
                transform: `rotateY(${warpy}deg) rotateX(${warpx}deg)`,
              }"
            >
              <div>
                <v-img
                  :src="creativeSrc"
                  :width="creativeWidth"
                  :height="creativeHeight"
                  :style="{
                    filter: `grayscale(${grayscale}) blur(${blur}px) contrast(${contrast}%) hue-rotate(${hue}deg) invert(${invert}%) sepia(${sepia}%) saturate(${saturate}%)`,
                  }"
                ></v-img>
              </div>
            </div>
          </v-img>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4>Result</h4>
        <v-img id="completed_mockup_bg"  :src='bgSrc'>
          <v-img id="completed_mockup_creative"></v-img>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
  data() {
    return {
      warpx: 0,
      warpy: 0,
      upPosition: 20,
      downPosition: 0,
      leftPosition: 0,
      rightPosition: 0,
      creativeWidth: 200,
      creativeHeight: 200,
      grayscale: null,
      blur: null,
      exposure: null,
      contrast: null,
      hue: null,
      opacity: null,
      invert: null,
      saturate: null,
      sepia: null,
      vueCanvas: null,
      mockupPart2: true,
      canvasBase64URL: null,
      bgSrc: "https://rebrand.bg/wp-content/uploads/2017/08/bilbord3-1.jpg",
      creativeSrc:
        "https://www.setaswall.com/wp-content/uploads/2017/10/Vermillion-Lake-Stars-Qhd-Wallpaper-1080x1920.jpg",
    };
  },
  methods: {
    loadImage(src, onload) {
      // http://www.thefutureoftheweb.com/blog/image-onload-isnt-being-called
      var img = new Image();

      img.onload = onload;
      img.src = src;

      return img;
    },
    saveScreenshot() {
      document.getElementById("completed_mockup_bg").src = this.bgSrc;
      document.getElementById(
        "completed_mockup_creative"
      ).src = this.creativeSrc;

        const el = this.$refs.insituPreview;
        const options = {
          type: "dataURL",
        };
        this.output = html2canvas(el, options).then((res) => {
          var a = document.createElement("a");
          a.setAttribute("href", res);
          a.setAttribute("download", "mockup.jpg");

          var aj = a;
          document.body.appendChild(aj);
          aj.click();
          aj.remove();
        });
    },
    changeUpPos() {
      this.upPosition = this.upPosition + 1;
    },
    createMockup() {
      this.mockupPart2 = true;
    },
  },
};
</script>